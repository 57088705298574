import { Component, OnInit } from '@angular/core';
// http
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
interface CUSTOMER {
  KeyValue:string;
  KeyCreat?:number;
  KeyExpire?:number;
  Name?:string;
  Birthday?:string;
  ExamDats?:string;
  keyvalue?:string;
  keycreat?:string;
  keyexpire?:string;
  name?:string;
  birthday?:string;
  examdats?:string;
  _id?:string;
  Edit?:boolean;
}
interface MONGOFORMAT {
  Collection:string;
  DbActive:string; 
  Key:string;
  Dat:CUSTOMER[];
  Op:string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  loginEvent:boolean = false;
  loginError:string = "";
  keyValue:string = "";
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };
  constructor(private http:HttpClient, private router: Router, private titleService: Title, private gtmService: GoogleTagManagerService) { }
  
  login(keyValue:string){
    const body = new HttpParams()
    .set('Collection', 'customer')
    .set('DbActive', 'find')
    .set('Key',keyValue)
    .set('Dat', '[]')
    .set('Op', 'validate keyValue')
    .set('ObjString',''); 
    this.loginError = "驗證中，請稍後...";
    this.http.post<CUSTOMER[]>("https://getpregnant.com.tw/api/mongofn", body.toString(), this.httpOptions).subscribe(
      value=>{
        //console.log(value)
        if(value===null){
          this.loginError = "驗證失敗，請檢查通行碼";
        }else{
          this.loginError = "轉向客戶頁";
          this.loginEvent = true;
          localStorage.setItem("token_front",keyValue);
          localStorage.setItem("profile_front",JSON.stringify(value));
          this.router.navigate(['/customer_profile']);
        }
      }
    )

  }
  ngOnInit() {
    this.titleService.setTitle("優德莎莉-問卷諮詢");
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
          const gtmTag = {
              event: 'page',
              pageName: item.url
          };

          this.gtmService.pushTag(gtmTag);
      }
  });
  }

}
