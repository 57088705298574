import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { CustomerComponent } from './customer/customer.component';
import { QuestionComponent } from './question/question.component';
import { ScoreComponent } from './score/score.component';
import { ProductUrlPipe } from './product-url.pipe';
import { TrustHTMLPipe } from './trust-html.pipe';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    CustomerComponent,
    QuestionComponent,
    ScoreComponent,
    ProductUrlPipe,
    TrustHTMLPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-TXCW36F',
    })
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
