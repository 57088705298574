import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'trustHTML'
})
export class TrustHTMLPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: string): unknown {
    if(value===undefined || typeof(value)==='number'){
      return(value);
    }
    value = value.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
