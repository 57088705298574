<div class="container-fluid ps-0 bg-image">
    <!-- Jumbotron -->
    <div class="logo"></div>
    <div class="jumbotron d-flex flex-wrap align-items-end justify-content-end">
      <div class="d-flex flex-column main-div justify-content-center align-items-stretch">
        <div class="submit-btn d-flex justify-content-end">
          <div class="home-image"></div>
          <div class="home-title d-flex justify-content-end align-items-end">智能備孕<br/>檢測系統</div>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-end">
            <input type="text" class="mt-xxl-5" placeholder="請輸入密碼" aria-label="請輸入密碼" [(ngModel)]="keyValue">
          </div>
          <div class="mt-4 submit-btn d-flex justify-content-end">
            <button class="btn d-flex align-items-center" type="button" (click)="login(keyValue)">
              <span class="arrow-pic">▶▶</span>
              <span >開始檢測</span>
            </button>
          </div>
        </div>
        <p class="d-flex justify-content-end mt-1" *ngIf="loginError!==''">{{loginError}}</p>
      </div>
    </div>
  </div>