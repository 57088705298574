<div class="page-container row d-flex justify-content-between align-items-stretch min-vh-100">
  <div class="col-xxl-3 col-xl-2 col-lg-1 left-bg"></div>
  <div class="col-xxl-6 col-xl-7 col-lg-9 p-0 bg-white shadow d-flex align-items-center flex-column">
    <div class="title-div d-flex justify-content-center align-items-end">
      <div class="title-div-background d-flex justify-content-end align-items-end">
        <div class="title">智能備孕<br/>檢測系統</div>
      </div>
    </div>
    <div class="d-flex justify-content-end jump-icon-div">
      <button (click)="back2Profile()"><div></div></button>
      <button (click)="scrollTop()"><div></div></button>
    </div>
    <div class="d-flex flex-column align-items-center score-div w-90 mb-3" [hidden]="!showResultDiv">
      <span [hidden]="!showResultDiv">本次檢測結果</span>
      <span [hidden]="!showResultDiv" [innerHTML]='frontPageTitle.dat.titleScore|trustHTML'></span>
      <span [hidden]="!showResultDiv">{{scoreObj===undefined?"":scoreObj.modelScore |  number: '1.0-0'}}</span>
    </div>
    <div class="w-90 accordion-div" [hidden]="!showResultDiv">
      <div class="title"><span [innerHTML]='frontPageTitle.dat.titleSymptom|trustHTML'></span></div>
      <div *ngIf="symptoms2UserArr!==undefined" class="accordion accordion-flush" id="symptoms2User">
        <div class="accordion-item" *ngFor="let item of symptoms2UserArr; let i = index">
          <h2 class="accordion-header" id="{{'symptom_heading'+i}}">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#symptom_collapse'+i" aria-expanded="false" aria-controls="collapseOne">
              {{item.symptom}}
            </button>
          </h2>
          <div id="{{'symptom_collapse'+i}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'symptom_heading'+i" data-bs-parent="#symptoms2User">
            <div class="accordion-body">
              <div [innerHTML]='item.content|trustHTML'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-90 accordion-div" [hidden]="!showResultDiv">
      <div class="title"><span [innerHTML]='frontPageTitle.dat.titleProduct|trustHTML'></span></div>
      <div *ngIf="productsArr!==undefined" class="accordion accordion-flush" id="products2User">
        <div class="accordion-item" *ngFor="let item of productsArr; let i = index">
          <h2 class="accordion-header" id="{{'product_heading'+i}}">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#product_collapse'+i" aria-expanded="false" aria-controls="collapseOne">
              {{item.product}}
            </button>
          </h2>
          <div id="{{'product_collapse'+i}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'product_heading'+i" data-bs-parent="#products2User">
            <div class="accordion-body">
              <div>
                <span [innerHTML]='frontPageTitle.dat.titleProduct_content|trustHTML'></span>
                <span [innerHTML]='item.content|trustHTML'></span>
              </div>
              <div class="mt-3">
                <span class="me-2" [innerHTML]='frontPageTitle.dat.titleProduct_url|trustHTML'></span>
                <a href="{{item.url}}" target='_blank' rel='noreferrer noopener'>{{item.url}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="{{'product_heading_coupon'}}">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#product_collapse_coupon'" aria-expanded="false" aria-controls="collapseOne">
              <span [innerHTML]='frontPageTitle.dat.titleCoupon|trustHTML'></span>
            </button>
          </h2>
          <div id="{{'product_collapse_coupon'}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'product_heading_coupon'" data-bs-parent="#products2User">
            <div class="accordion-body">
              <span [innerHTML]='frontPageTitle.dat.titleCoupon_content|trustHTML'></span>
            </div>
          </div>
        </div> 
      </div>
    </div>
    <div class="w-90 accordion-div mb-3" [hidden]="!showResultDiv">
      <div class="title"><span [innerHTML]='frontPageTitle.dat.titleHabit|trustHTML'></span></div>
      <div *ngIf="habits2UserArr!==undefined" class="accordion accordion-flush" id="habits2User">
        <div class="accordion-item" *ngFor="let item of habits2UserArr; let i = index">
          <h2 class="accordion-header" id="{{'habit_heading'+i}}">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#habit_collapse'+i" aria-expanded="false" aria-controls="collapseOne">
              {{item.habit}}
            </button>
          </h2>
          <div id="{{'habit_collapse'+i}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'habit_heading'+i" data-bs-parent="#habits2User">
            <div class="accordion-body">
              <div [innerHTML]='item.content|trustHTML'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-90 share-div d-flex justify-content-evenly align-items-start mt-3" [hidden]="!showResultDiv">
      <div class="d-flex flex-column justify-content-center">
        <div (click)="testLine(line.dat.shareUrl,line.dat.shareText,scoreObj,'friend');" [hidden]="!showResultDiv"></div>
        <div class="mt-3">
          <span [hidden]="!showResultDiv" [innerHTML]='frontPageTitle.dat.titleLineFriend|trustHTML'></span>
        </div>
      </div>      
      <div class="d-flex flex-column justify-content-center">
        <div (click)="testLine(line.dat.shareUrl,line.dat.shareText,scoreObj,'all');" [hidden]="!showResultDiv"></div>
        <div class="mt-3">
          <span [hidden]="!showResultDiv" [innerHTML]='frontPageTitle.dat.titleLineAll|trustHTML'></span>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <div (click)="makePdf();" [hidden]="!showResultDiv"></div>
        <div class="mt-3">
          <span [hidden]="!showResultDiv" [innerHTML]='frontPageTitle.dat.titlePdf|trustHTML'></span>
        </div>
      </div>
    </div>
    <!-- PDF START -->
    <div #resDiv class="w-90 hiddenPDF" id="pdf-res" style="width: 1400px;min-height:1500px">
      <div class="d-flex justify-content-end w-90" id="pdf-logo">
      </div>
      <div class="d-flex flex-column align-items-center score-div w-90" [hidden]="!showResultDiv" id="score-section">
        <span [hidden]="!showResultDiv">本次檢測結果</span>
        <span [hidden]="!showResultDiv" [innerHTML]='frontPageTitle.dat.titleScore|trustHTML'></span>
        <span [hidden]="!showResultDiv" style="color: #F28490;">{{scoreObj===undefined?"":scoreObj.modelScore |  number: '1.0-0'}}</span>
      </div>
      <div style="width: 10px;height: 30px;"><br></div>
      <div id="symptoms-section">
        <div class="title"><span [innerHTML]='frontPageTitle.dat.titleSymptom|trustHTML'></span></div>
        <div *ngIf="symptoms2UserArr!==undefined" class="accordion accordion-flush" id="symptoms2User_pdf">
          <div class="accordion-item" *ngFor="let item of symptoms2UserArr; let i = index">
            <h2 class="accordion-header" id="{{'symptom_heading_pdf'+i}}">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#symptom_collapse'+i" aria-expanded="false" aria-controls="collapseOne">
                {{item.symptom}}
              </button>
            </h2>
            <div id="{{'symptom_collapse_pdf'+i}}" class="accordion-collapse collapse show" [attr.aria-labelledby]="'symptom_heading'+i" data-bs-parent="#symptoms2User">
              <div class="accordion-body" >
                <span class="pdf-span"><div><span [innerHTML]='item.content|trustHTML'></span></div></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="products-section">
        <div class="title"><span [innerHTML]='frontPageTitle.dat.titleProduct|trustHTML'></span></div>
        <div *ngIf="productsArr!==undefined" class="accordion accordion-flush" id="products2User_pdf">
          <div class="accordion-item" *ngFor="let item of productsArr; let i = index">
            <h2 class="accordion-header" id="{{'product_heading_pdf'+i}}">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#product_collapse'+i" aria-expanded="false" aria-controls="collapseOne">
                {{item.product}}
              </button>
            </h2>
            <div id="{{'product_collapse_pdf'+i}}" class="accordion-collapse collapse show" [attr.aria-labelledby]="'product_heading'+i" data-bs-parent="#products2User">
              <div class="accordion-body">
                <span class="pdf-span"><div>
                  <span [innerHTML]='frontPageTitle.dat.titleProduct_content|trustHTML'></span>
                  <span [innerHTML]='item.content|trustHTML'></span>
                </div></span>
                <div class="mt-3"></div>
                <span class="pdf-span"><div >
                  <span class="me-2" [innerHTML]='frontPageTitle.dat.titleProduct_url|trustHTML'></span>
                  <a href="{{item.url}}" target='_blank' rel='noreferrer noopener'>{{item.url}}</a>
                </div></span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="{{'product_heading_coupon_pdf'}}">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#product_collapse_coupon'" aria-expanded="false" aria-controls="collapseOne">
                <span [innerHTML]='frontPageTitle.dat.titleCoupon|trustHTML'></span>
              </button>
            </h2>
            <div id="{{'product_collapse_coupon_pdf'}}" class="accordion-collapse collapse show" [attr.aria-labelledby]="'product_heading_coupon'" data-bs-parent="#products2User">
              <div class="accordion-body">
                <span class="pdf-span"><span [innerHTML]='frontPageTitle.dat.titleCoupon_content|trustHTML'></span></span>
              </div>
            </div>
          </div> 
        </div>
      </div>
      <div id="habits-section">
        <div class="title"><span [innerHTML]='frontPageTitle.dat.titleHabit|trustHTML'></span></div>
        <div *ngIf="habits2UserArr!==undefined" class="accordion accordion-flush" id="habits2User_pdf">
          <div class="accordion-item" *ngFor="let item of habits2UserArr; let i = index">
            <h2 class="accordion-header" id="{{'habit_heading_pdf'+i}}">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#habit_collapse'+i" aria-expanded="false" aria-controls="collapseOne">
                {{item.habit}}
              </button>
            </h2>
            <div id="{{'habit_collapse_pdf'+i}}" class="accordion-collapse collapse show" [attr.aria-labelledby]="'habit_heading'+i" data-bs-parent="#habits2User">
              <div class="accordion-body">
                <span class="pdf-span"><div><span  [innerHTML]='item.content|trustHTML'></span></div></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="survey-container contentcontainer codecontainer">
    <div id="surveyElement"></div>
  </div>
  <div class="foot-div d-flex justify-content-center mb-3 align-items-end" (click)="back2Profile()">
    <div></div>
    <div class="ms-1">返回首頁</div>
  </div>
  </div>
  <div class="col-xxl-3 col-xl-2 col-lg-1 right-bg"></div>
 </div>