import { Component, OnInit, ElementRef, Renderer2,ViewChild, HostListener} from '@angular/core';
// http
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { retry,takeUntil } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
interface CUSTOMER {
  KeyValue?:string;
  KeyCreat?:number;
  KeyExpire?:number;
  Name?:string;
  Birthday?:string;
  ExamDats?:string;
  keyvalue?:string;
  keycreat?:number;
  keyexpire?:string;
  name?:string;
  birthday?:string;
  examdats?:string;
  _id?:string;
  Edit?:boolean;
  objstring?:string;
  obj:{modelScore:string,product:string[],habits2User:string[]};
}
interface MONGOFORMAT {
  Collection:string;
  DbActive:string; 
  Key:string;
  Dat:CUSTOMER[];
  Op:string;
  ObjString:string;
}
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.sass']
})
export class CustomerComponent implements OnInit {
  @ViewChild("resDiv") divView?: ElementRef;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  customer:CUSTOMER = {obj:{modelScore:'',product:[],habits2User:[]}};
  tmpCustomer:CUSTOMER = {obj:{modelScore:'',product:[],habits2User:[]}};
  editStatus:boolean = false;
  grep2MongoTip:boolean = false;
  feedbackTable:CUSTOMER[] = [{obj:{modelScore:'',product:[],habits2User:[]}}];
  checkProfile!:boolean;
  resHeight:string = '100%';
  newExamHeight:string = '100%';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };
  private isCached = false; // for pageshow event
  constructor(private http: HttpClient, private router: Router, private titleService: Title, private elementRef:ElementRef, private renderer:Renderer2, 
    private gtmService: GoogleTagManagerService) { }
  editProfile(){
    this.editStatus = true;
  }
  saveProfile(){
    if(this.customer._id!==undefined){
      const body = new HttpParams()
      .set('Collection', 'customer')
      .set('DbActive', 'update')
      .set('Key',this.customer._id)
      .set('Dat', JSON.stringify([this.customer]))
      .set('Op', 'one')
      .set('ObjString',''); 
      let upload2Mongo:Observable<string> = this.http.post<string>("https://getpregnant.com.tw/api/mongofn", body.toString(), this.httpOptions).pipe(retry(0),takeUntil( this.ngUnsubscribe));
      upload2Mongo.subscribe(
          value=>{
            if(value=="success"){
              this.editStatus = false;
              localStorage.setItem("profile_front",JSON.stringify(this.customer));
              this.tmpCustomer = JSON.parse(JSON.stringify(this.customer));
              this.checkProfile = this.customer.name!=="" && this.customer.birthday!=="";
            }
          },
        error => {
          // console.log("update profile values fail")
          // console.log(error)
        } // error path
      ); 
    }
  }
  newQuestion(){
    if(this.checkProfile){
      this.router.navigate(['/questionnaire']);
    }
  }
  reviewRecord(surveyData:any){
    this.router.navigate(['/questionnaire/review'],{state: {data: surveyData}});
  }
  getFeedbackTable(){
    if(localStorage.getItem("token_front")!==null){

      const body = new HttpParams()
      .set('Collection', 'score_record')
      .set('DbActive', 'find')
      .set('Key',(localStorage.getItem("token_front") || ""))
      .set('Dat', JSON.stringify([{obj:{modelScore:'',product:[]}}]))
      .set('Op', 'filter-keyvalue')
      .set('ObjString',''); 
      this.http.post<CUSTOMER[]>("https://getpregnant.com.tw/api/mongofn", body.toString(), this.httpOptions).subscribe(
        value=>{
          if(value===null){
            this.grep2MongoTip = true;
          }else{
            this.feedbackTable = value;
            this.feedbackTable.forEach(v=>{
              v.obj = JSON.parse(v.objstring || '{}');
              return(v)
            })
            this.grep2MongoTip = true;
          }
          if(this.divView!==undefined){
            let width:number = this.divView.nativeElement.children[0].children[0].offsetWidth -4;
            this.newExamHeight = (width + 4) + "px";
            this.resHeight = (width-45)/2 + "px";
            // console.log(width);
            // console.log(this.resHeight);
          }
        }
      )
    }

  }
  checkLoginStatu(keyValue:string){
    const body = new HttpParams()
    .set('Collection', 'customer')
    .set('DbActive', 'find')
    .set('Key',keyValue)
    .set('Dat', '[]')
    .set('Op', 'validate keyValue')
    .set('ObjString',''); 
    this.http.post<CUSTOMER[]>("https://getpregnant.com.tw/api/mongofn", body.toString(), this.httpOptions).subscribe(
      value=>{
        //console.log(value)
        if(value===null){
          this.router.navigate(['/home']);
        }else{
          localStorage.setItem("token_front",keyValue);
          localStorage.setItem("profile_front",JSON.stringify(value));
          this.customer = JSON.parse(localStorage.getItem("profile_front") || '{}');
          this.tmpCustomer = JSON.parse(JSON.stringify(this.customer));
          if(parseInt(this.customer.keyexpire===undefined?"":this.customer.keyexpire) < new Date().getTime()){
            this.router.navigate(['/home']);
          }
    
          this.checkProfile = this.customer.name!=="" && this.customer.birthday!=="";
          this.getFeedbackTable();
          this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageName: item.url
                };
                this.gtmService.pushTag(gtmTag);
            }
          });
        }
      }
    )

  }
  logout(){
    localStorage.removeItem("token_front");
    localStorage.removeItem("profile_front");
    this.router.navigate(['/home']);
  }

  @HostListener('pageshow', ['$event'])
  onPageShow() {
    if (this.isCached) {
      let keyValue:string | null = localStorage.getItem("token_front");
      if(keyValue!==null){
        this.checkLoginStatu(keyValue);
      }else{
        this.router.navigate(['/home']);
      }
    }
  }


  ngOnInit() {
    this.isCached = false;
    if(localStorage.getItem("token_front")===null || localStorage.getItem("token_front")==='' || localStorage.getItem("token_front")===undefined){
      this.router.navigate(['/home']);
    }else{
      this.titleService.setTitle("優德莎莉-問卷諮詢");
      let keyValue:string | null = localStorage.getItem("token_front");
      if(keyValue!==null){
        this.checkLoginStatu(keyValue);
      }else{
        this.router.navigate(['/home']);
      }
    }
  }

}
