import { Component,OnInit,ElementRef,Renderer2,ViewChild} from '@angular/core';
//survey
import * as Survey from 'survey-angular';
import 'bootstrap/dist/css/bootstrap.css';
import "survey-angular/survey.css";
//pdf
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { Title } from '@angular/platform-browser';

// http
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { retry,takeUntil } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

Survey.StylesManager.applyTheme("default");
Survey.defaultBootstrapCss.navigationButton = "btn btn-green";
interface CUSTOMER {
  KeyValue:string;
  KeyCreat?:number;
  KeyExpire?:number;
  Name?:string;
  Birthday?:string;
  ExamDats?:string;
  keyvalue?:string;
  keycreat?:string;
  keyexpire?:string;
  name?:string;
  birthday?:string;
  examdats?:string;
  _id?:string;
  Edit?:boolean;
  ObjString?:string;
}
interface QuestionType {
  type: string;
  name: string;
  title: string;
  isRequired: boolean;
  hasNone: boolean;
  colCount: number;
  choices: string[];
  hasOther: boolean; 
  otherText:string;
  visibleIf:string;
}
interface PageType {
  name: string;
  elements: QuestionType[];
}
interface MONGOFORMAT {
  Collection:string;
  DbActive:string; 
  Key:string;
  Dat:any;
  Op:string;
  ObjString?:string;
  _id?:string;
  [key: number]: {objstring?:string};
}
@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.sass']
})
export class QuestionComponent implements OnInit {
  @ViewChild("resDiv") divView?: ElementRef;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  question:QuestionType[] =[];
  survey!:Survey.Model;
  p_surveyComplete:boolean = false;
  grepFromMongoTip:boolean = false;
  line:{dat:{shareUrl:string,shareText:string,scoreTitle:string},id:string} = {dat:{shareUrl:"",shareText:"",scoreTitle:""},id:""}; //line分享資料
  frontPageTitle:{dat:{titleScore:string,titleSymptom:string,titleProduct:string,titleProduct_content:string,titleProduct_url:string,titleHabit:string,titleLineAll:string,titleLineFriend:string,titlePdf:string,
    titleCoupon:string, titleCoupon_content:string},id:string} =  
  {dat:{titleScore:"",titleSymptom:"",titleProduct:"",titleProduct_content:"",titleProduct_url:"",titleHabit:"",titleLineAll:"",titleLineFriend:"",titlePdf:"",
  titleCoupon:"", titleCoupon_content:""},id:""}; //前台頁面標題資料
  questionDat:{dat:any,id:string|undefined} = {dat:undefined,id:undefined};//問卷資料
  symptomDat:{dat:any,id:string|undefined} = {dat:undefined,id:undefined};//症狀資料
  symptoms:Set<string> = new Set();
  symptomsArr:string[] = [];
  symptoms2User:Set<string> = new Set();
  symptoms2UserArr:{symptom:string,content:string}[] = [];
  symptomContentDat:{dat:any,id:string|undefined} = {dat:undefined,id:undefined};
  modelDat:{dat:any,id:string|undefined} = {dat:undefined,id:undefined};//模型資料
  productDat:{dat:any,id:string|undefined} = {dat:undefined,id:undefined};//營養品資料
  products:Set<string> = new Set();
  productsArr:{product:string,url:string,content:string}[] = [];
  habitDat:{dat:any,id:string|undefined} = {dat:undefined,id:undefined}; //生活習慣資料
  habits2User:Set<string> = new Set();
  habits2UserArr:{habit:string,content:string}[] = [];
  scoreObj:any = {};
  uploadRes2MongoTip:boolean = true;
  showResultDiv:boolean = false;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };
  math = Math;

  resetSurvey(){
    this.survey.clear();
    this.survey.render();
  }

  back2Profile(){
    this.router.navigate(['/customer_profile']);
  }
  scrollTop(){
    window.scroll(0,0);
  }
  uploadScoreSurveyRes2Mongo(scoreObj:any){
    this.uploadRes2MongoTip = false;
    const date_curr = new Date().getTime();
    if(localStorage.getItem("token_front")!==null){
      var customer:CUSTOMER = {KeyValue:(localStorage.getItem("token_front") || '')};
      customer.KeyCreat = date_curr;
      customer.KeyExpire = JSON.parse(localStorage.getItem("profile_front") || '{keyexpire:""}').keyexpire;
      customer.ObjString = JSON.stringify(scoreObj);
      const body = new HttpParams()
      .set('Collection', 'score_record')
      .set('DbActive', 'create')
      .set('Key','')
      .set('Dat', JSON.stringify([customer]))
      .set('Op', '')
      .set('ObjString',''); 
      let upload2Mongo:Observable<string> = this.http.post<string>("https://getpregnant.com.tw/api/mongofn", body.toString(), this.httpOptions).pipe(retry(0),takeUntil( this.ngUnsubscribe));
      upload2Mongo.subscribe(
          value=>{
            if(value=="success"){
              this.uploadRes2MongoTip = true;
              this.showResultDiv = true;
            }
          },
        error => {
          // console.log("upload key values fail")
          // console.log(error)
        } // error path
      );
    }else{
      this.router.navigate(['/home']);
    }

  }
  confirmRequire(itemInfo:any){
    let v_or = itemInfo["必填題號"].split("or").map((v_and:string)=>{
      v_and = v_and.trim();
      v_and = v_and.split("and").map((v:string)=>{
        v = v.trim();
        let q_name:string = v.replace(/(^{)|(}=.*)/g,"");
        if(Object.keys(this.survey.data).indexOf(q_name)>-1){ // answer the specify question?
          return (this.survey.data[q_name].indexOf(v.replace(/(.*\=')|('$)/g,"").trim())>-1)?"1":"0";  // answer the specify question with specify answer?
        }else{
          return "0";
        }
      }).join("*");
      v_and = (eval(v_and) < 0.0000000001)?"0":"1";
      return v_and;
    }).join("+");
    return(v_or)
  }
  scoreSurveyRes(){
    var scoreObj:{[k: string]: any} = {overallScore:0};
    this.products = new Set();
    this.symptoms2User = new Set();
    for(let s of this.symptomsArr){
      scoreObj[s] = 0;
    }
    Object.keys(this.survey.data).forEach(v=>{
      //症狀分析
      for(let symptomPerPage of this.symptomDat.dat){
        let question_name:string[] = symptomPerPage.map((m_p_p:any)=>{return(m_p_p.問題)});
        if(question_name.indexOf(v)>-1){
          for(let i= question_name.indexOf(v);
          i<(symptomPerPage[question_name.indexOf(v)]["choiceNum"]+question_name.indexOf(v));
          i++){
            if(typeof(this.survey.data[v])==="string"){
              if(this.survey.data[v]===symptomPerPage[i]["選項"]){
                for(let s in scoreObj){
                  if(symptomPerPage[i][s]!==undefined){
                    scoreObj[s] = scoreObj[s] + symptomPerPage[i][s];
                  }
                }
                break;
              }
            }else if(typeof(this.survey.data[v])==="object"){ // >=1 answers
              this.survey.data[v].forEach((ans:any)=>{
                if(ans===symptomPerPage[i]["選項"]){
                  for(let s in scoreObj){
                    if(symptomPerPage[i][s]!==undefined){
                      scoreObj[s] = scoreObj[s] + symptomPerPage[i][s];
                    }
                  }
                }                
              })
            }
          }
          break;
        }else{
          continue;
        }
      }
      //生活習慣分析
      let question_habit:string[] = this.habitDat.dat.map((v:any)=>{
        return(v.問題)
      })
      if(question_habit.indexOf(v)>-1){
        for(let i= question_habit.indexOf(v);
        i<(this.habitDat.dat[question_habit.indexOf(v)]["choiceNum"]+question_habit.indexOf(v));
        i++){
          if(typeof(this.survey.data[v])==="string"){
            if(this.survey.data[v]===this.habitDat.dat[i]["選項"] && this.habitDat.dat[i]["生活習慣建議"]!==undefined){
              if(this.habitDat.dat[i]["必填題號"]!==undefined){
                let v_or = this.confirmRequire(this.habitDat.dat[i]);
                let isRequired:Boolean = eval(v_or)>0.0000000001;//this.habitDat.dat[i]["必填"]
                if(isRequired){
                  this.habits2User.add(JSON.stringify({habit:this.habitDat.dat[i]["生活習慣建議"],content:this.habitDat.dat[i]["內容"]}));
                }
              }else{
                this.habits2User.add(JSON.stringify({habit:this.habitDat.dat[i]["生活習慣建議"],content:this.habitDat.dat[i]["內容"]}));
              }
              break;
            }
          }else if(typeof(this.survey.data[v])==="object"){ // >=1 answers
            this.survey.data[v].forEach((ans:any)=>{
              if(ans===this.habitDat.dat[i]["選項"] && this.habitDat.dat[i]["生活習慣建議"]!==undefined){
                if(this.habitDat.dat[i]["必填題號"]!==undefined){
                  let v_or = this.confirmRequire(this.habitDat.dat[i]);
                  let isRequired:Boolean = eval(v_or)>0.0000000001;//this.habitDat.dat[i]["必填"]
                  if(isRequired){
                    this.habits2User.add(JSON.stringify({habit:this.habitDat.dat[i]["生活習慣建議"],content:this.habitDat.dat[i]["內容"]}));
                  }
                }else{
                  this.habits2User.add(JSON.stringify({habit:this.habitDat.dat[i]["生活習慣建議"],content:this.habitDat.dat[i]["內容"]}));
                }           
              }                
            })
          }
        }
      }
    })
    for(let s of this.symptomsArr){
      if(s==="overallScore"){continue;}
      scoreObj["overallScore"] = scoreObj["overallScore"] + scoreObj[s];
      for (let itemInfo of this.productDat.dat) { // loop for 營養品
        if(scoreObj[s] <= itemInfo[s]){
          if(itemInfo["必填題號"]!==undefined){
            let v_or = this.confirmRequire(itemInfo);
            if(eval(v_or)>0.0000000001){
              this.products.add(JSON.stringify({product:itemInfo["營養品"],url:itemInfo["產品連結"],content:itemInfo["營養品介紹"]}));
            }
          }else{
            this.products.add(JSON.stringify({product:itemInfo["營養品"],url:itemInfo["產品連結"],content:itemInfo["營養品介紹"]}));
          }
        }
      }
      if(scoreObj[s] <= this.symptomContentDat.dat[this.symptomContentDat.dat.map((v:any)=>{return(v.症狀結果)}).indexOf(s)].症狀診斷門檻值){
        this.symptoms2User.add(JSON.stringify({symptom:s,content:this.symptomContentDat.dat[this.symptomContentDat.dat.map((v:any)=>{return(v.症狀結果)}).indexOf(s)].內容}));
      }
    }

    let habits2UserArr:string[] = Array.from(this.habits2User);
    this.habits2UserArr = habits2UserArr.map(v=>{return(JSON.parse(v))});
    scoreObj["habits2User"] = JSON.stringify(this.habits2UserArr);
    let productsArr:string[] = Array.from(this.products);
    this.productsArr = productsArr.map(v=>{return(JSON.parse(v))});
    scoreObj["product"] = JSON.stringify(this.productsArr);
    let symptoms2UserArr:string[] = Array.from(this.symptoms2User);
    this.symptoms2UserArr = symptoms2UserArr.map(v=>{return(JSON.parse(v))});
    scoreObj["symptoms2User"] = JSON.stringify(this.symptoms2UserArr);
    this.modelDat.dat[0]["模型"] = "Math.round("+this.modelDat.dat[0]["模型"]+")";
    scoreObj["modelScore"] = eval(this.modelDat.dat[0]["模型"].replace("y",scoreObj["overallScore"].toString()));
    scoreObj["data"] = JSON.stringify(this.survey.data);
    scoreObj["surveyModel"] = JSON.stringify(this.questionDat.dat);
    this.scoreObj = scoreObj;
    this.uploadScoreSurveyRes2Mongo(scoreObj);
  }
  testLine(url:string,text:string,scoreObj:any,shareMode:string){
    switch(shareMode) { 
      case 'all': { 
        // let sympton:string = JSON.parse(scoreObj["symptoms2User"]).map((v:{symptom:string,content:string})=>{return(v.symptom+" : "+ v.content + "%0D%0A")}).join("");
        // let product:string = JSON.parse(scoreObj["product"]).map((v:{product:string,content:string,url?:string})=>{return(v.product+" : "+ v.content + " "+
        // (v.url!==undefined?v.url:"")+"%0D%0A")}).join("");
        // let habits2User:string = JSON.parse(scoreObj["habits2User"]).map((v:{habit:string,content:string})=>{return(v.habit+" : "+ v.content + "%0D%0A")}).join("");
        let sympton:string = JSON.parse(scoreObj["symptoms2User"]).map((v:{symptom:string})=>{return(v.symptom)}).join("、")+ "%0D%0A";
        let product:string = JSON.parse(scoreObj["product"]).map((v:{product:string,url?:string})=>{return(v.product+ 
        (v.url!==undefined?(" "+v.url):""))}).join("、")+ "%0D%0A";
        let habits2User:string = JSON.parse(scoreObj["habits2User"]).map((v:{habit:string})=>{return(v.habit)}).join("、");
        text = text + "%0D%0A" + 
        this.frontPageTitle.dat.titleScore.replace(/(\<u\>)|(\<i\>)|(\<b\>)|(\<\/u\>)|(\<\/i\>)|(\<\/b\>)/g,"") +  scoreObj.modelScore + "%0D%0A" +
        this.frontPageTitle.dat.titleSymptom.replace(/(\<u\>)|(\<i\>)|(\<b\>)|(\<\/u\>)|(\<\/i\>)|(\<\/b\>)/g,"") + "%0D%0A" + 
         (sympton === ""?"%0D%0A":sympton) +
         this.frontPageTitle.dat.titleProduct.replace(/(\<u\>)|(\<i\>)|(\<b\>)|(\<\/u\>)|(\<\/i\>)|(\<\/b\>)/g,"") + "%0D%0A" + 
         (product === ""?"%0D%0A":product) +
         this.frontPageTitle.dat.titleHabit.replace(/(\<u\>)|(\<i\>)|(\<b\>)|(\<\/u\>)|(\<\/i\>)|(\<\/b\>)/g,"") + "%0D%0A" +
         (habits2User === ""?"%0D%0A":habits2User); 
         break; 
      } 
      case 'friend': { 
        text = text + "%0D%0A" + 
        this.frontPageTitle.dat.titleScore.replace(/(\<u\>)|(\<i\>)|(\<b\>)|(\<\/u\>)|(\<\/i\>)|(\<\/b\>)/g,"")+":" +  scoreObj.modelScore; 
         break; 
      } 
      default: { 
         //statements; 
         break; 
      } 
   }
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    var lineUrl:string = "http://line.naver.jp/R/msg/text/?" + text + "%0D%0A" + encodeURIComponent(url);
    } else {
      // 網頁版語法
      var lineUrl:string = "https://lineit.line.me/share/ui?url=" 
      + encodeURIComponent(url) + "&text="+text;
    }
    window.open(lineUrl);

  }
  public getQuestions(pages:PageType[]) {
    this.grepFromMongoTip = false;
    const surveyJSON = {
      "showProgressBar": "both",
      "progressBarType":"questions",
      "pageNextText":"下一頁",
      "pagePrevText":"上一頁",
      "completeText":"提交答案",
      "completedHtml":"<span></span>",
      "pages": pages,
      "showQuestionNumbers": "on"
     };
    this.survey = new Survey.Model(surveyJSON);
    this.grepFromMongoTip = true;
    Survey.SurveyNG.render("surveyElement", { model: this.survey });
    this.renderer.listen(
      this.elementRef.nativeElement.
      querySelector("#surveyElement > div > form > div.sv_container > div > div.sv_nav > input.sv_complete_btn"), 
      'click', (event) => { this.scoreSurveyRes();});
      this.survey.onComplete.add(function (sender) {
    });
    if(this.router.url==='/questionnaire/review'){
      this.survey.data = JSON.parse(this.scoreObj.data);
      this.survey.mode = "display";
    }
  }
  loadTest(){
    let testAnswer:{} = JSON.parse('{"飲食習慣1":"是","飲食習慣2":"否","飲食習慣3":"否","飲食習慣4":"是","飲食習慣5":"是","咖啡因攝取":"是","塑化劑暴露":"是","行房頻率":"是","睡眠品質(熬夜)":"是","壓力指數":"是","心理狀態":"還好，有努力就有機會","日曬頻率":"是","抽菸習慣":"是","酗酒習慣":"是","運動習慣":"是","年齡":"30以下","BMI":"18.5-24","平均經血量":"少於3天","經痛":"輕度","骨盆腔":"無","月經週期":">35天","流產病史1":"否","婦科病史":["子宮肌瘤"],"AMH":">3 ","泌乳激素":">25","用藥史":"否"}');
    this.survey.data = testAnswer;
  }
  getDatFromMongo(collection:string,datName:string){
    this.grepFromMongoTip = false;
    const body = new HttpParams()
    .set('Collection', collection)
    .set('DbActive', 'find')
    .set('Key','')
    .set('Dat', '[]')
    .set('Op', 'all')
    .set('ObjString',''); 
    let upload2Mongo:Observable<MONGOFORMAT> = this.http.post<MONGOFORMAT>("https://getpregnant.com.tw/api/mongofn", body.toString(), this.httpOptions).pipe(retry(0),takeUntil( this.ngUnsubscribe));
    upload2Mongo.subscribe(
        value=>{
          if(value!==null){
            var this_datName:{dat:any,id:string|undefined} = {dat:undefined,id:undefined};
            switch (datName) {
              case "questionDat":
                this_datName = this.questionDat;
                break;
              case "modelDat":
                this_datName = this.modelDat;
                break;
              case "symptomDat":
                this_datName = this.symptomDat;
                break;
              case "productDat":
                this_datName = this.productDat;
                break;
              case "habitDat":
                this_datName = this.habitDat;
                break;        
              case "symptomContentDat":
                this_datName = this.symptomContentDat;
                break; 
              case "line":
                this_datName = this.line;
                break; 
              case "frontPageTitle":
                this_datName = this.frontPageTitle;
                break; 
              default:
                break;
            }
            this_datName.dat = JSON.parse(value[0].objstring || '{}');
            this_datName.id = value._id;
            if(datName === "questionDat"){
              this.getQuestions(this.questionDat.dat);
            }else if(collection==="symptom"){
              this.symptoms = new Set();
              for(let i in this_datName.dat){
                for(let j in this_datName.dat[i]){
                  Object.keys(this_datName.dat[i][j]).forEach(v=>{
                    if(v!=="問題" && v!=="選項" && v!=="choiceNum"){
                      this.symptoms.add(v);
                    }
                  });
                }
              }
              this.symptomsArr = Array.from(this.symptoms);
              this.grepFromMongoTip = true;
            }else if(collection==="product" || collection==="habit" || collection==="symptom_content"){
              //unlist array in each page
              this_datName.dat = this_datName.dat.reduce(
                function(a:any, b:any) {
                  return a.concat(b);
                },
                []
              );
            }else{
              //console.log(this_datName.dat)
            }
          }
        },
      error => {
        // console.log("find key values fail")
        // console.log(error)
      } // error path
    );
  }
  logout(){
    localStorage.removeItem("token_front");
    localStorage.removeItem("profile_front");
    this.router.navigate(['/home']);
  }
  setPdfDom(data:any){
    data.setAttribute('style', 'visibility:hodden')
  }
  makePdf() { 
        if(this.divView!==undefined){
          let margin = 30;
          let scale = 2;
          if( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            scale = 1.1;
          }
          var data = this.divView.nativeElement;
          let pageHeight:number = 2250;
          let marginSpace:number = 300;
          let pageCount:number = Math.ceil(data.offsetHeight / pageHeight);
          let fillSpace:number = data.children[0].offsetHeight + data.children[1].offsetHeight + data.children[2].offsetHeight; // logo + score + spaceDiv
          let div_level1:number = 3;
          let div_level2:number = 0;
          let write_sub_div:boolean = false;
          for(let perPage:number = 0;perPage < pageCount;perPage++){
            let pageSpace:number = pageHeight - fillSpace; // pageSpace:一頁剩下的空間; fillSpace:已填入的空間
            for(let i:number = div_level1; i < data.children.length;i++){
              let changePage:boolean = false;
              if(((data.children[i].offsetHeight) <= pageSpace) && !write_sub_div){
                pageSpace = pageSpace - data.children[i].offsetHeight;
                fillSpace = fillSpace + data.children[i].offsetHeight;
                div_level1 = div_level1 +1;
                div_level2 = 0;
                continue;
              }else{
                if(div_level2 == 0){
                  if((data.children[i].children[0].offsetHeight + data.children[i].children[1].children[0].offsetHeight + fillSpace) > pageSpace){
                    this.renderer.setStyle(data.children[i-1],"marginBottom",(pageSpace)+"px");//換頁
                    fillSpace = 0;
                    div_level1 = i;
                    break;
                  }else{
                    pageSpace = pageSpace - data.children[i].children[0].offsetHeight;
                    fillSpace = data.children[i].children[0].offsetHeight + fillSpace;
                  }
                }
                for(let j:number=div_level2;j<data.children[i].children[1].children.length;j++){
                  if((data.children[i].children[1].children[j].offsetHeight) <= ( pageSpace- marginSpace)){
                    pageSpace = pageSpace - data.children[i].children[1].children[j].offsetHeight;
                    fillSpace = data.children[i].children[1].children[j].offsetHeight + fillSpace;
                    if(j===(data.children[i].children[1].children.length-1)){
                      div_level2 = 0;
                      write_sub_div = false;
                    }
                    continue;
                  }else{
                      this.renderer.setStyle(data.children[i].children[1].children[j-1],"marginBottom",( pageSpace- 60)+"px");
                      div_level1 = i;
                      div_level2 = j;
                      fillSpace = 0;
                      changePage = true;
                      write_sub_div = true;
                      break;
                  }
                }
                if(changePage){
                  break;
                }
              }
            }
          }
          html2canvas(data,
          { scale: scale,
            scrollY: 0,
            scrollX: 0,
            width: parseInt(data.clientWidth)*1.1,
            backgroundColor: '#fff'  }
          ).then(canvas => {
            var contentWidth = canvas.width;
            var contentHeight = canvas.height;
      
            //一頁pdf顯示html頁面生成的canvas高度;
            var pageHeight = contentWidth / 592.28 * 841.89;
            //未生成pdf的html頁面高度
            var leftHeight = contentHeight;
            //頁面偏移
            var position = 0;
            //a4紙的尺寸[595.28,841.89]，html頁面生成的canvas在pdf中圖片的寬高
            var imgWidth = 595.28;
            var imgHeight = 592.28/contentWidth * contentHeight;
      
            var pageData = canvas.toDataURL('image/jpeg', 1.0);
      
            var pdf = new jsPDF('p', 'pt', 'a4');
            //有兩個高度需要區分，一個是html頁面的實際高度，和生成pdf的頁面高度(841.89)
            //當內容未超過pdf一頁顯示的範圍，無需分頁
            if (leftHeight < pageHeight) {
              pdf.addImage(pageData, 'JPEG', margin, 0, imgWidth, imgHeight );
            } else {
                while(leftHeight > 0) {
                    pdf.addImage(pageData, 'JPEG', margin, position, imgWidth, imgHeight)
                    leftHeight -= pageHeight;
                    position -= 841.89;
                    //避免新增空白頁
                    if(leftHeight > 0) {
                      pdf.addPage();
                    }
                }
            }
            pdf.save('優德莎莉-問卷結果.pdf');
          });
          // var doc = new jsPDF('p', 'pt','a4',true);
          // let margin = 30;
          // var scale = 0.6;
          // // if( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          // //   var scale = 1.3;
          // // }else{
          // //   var scale = (doc.internal.pageSize.width - margin * 2) / data.scrollWidth;
          // // }
          // //doc.addImage("/assets/images/eatlohas-logo.png", "PNG", doc.internal.pageSize.width - margin - 154, margin, 154, 47);
          // console.log(doc.internal.pageSize.width)
          // doc.addImage("/assets/images/pc/1備孕網頁-素材(首頁logo).png", "PNG", doc.internal.pageSize.width - margin - 154, margin-5, 154, 47);
          // doc.html(data, {
          //   callback: function (doc) {
          //     doc.save('優德莎莉-問卷結果.pdf');
          //   },
          //   margin: [margin,margin,margin,margin],
          //   image:{type: "jpeg",quality:300},
          //   x:margin,
          //   y:margin,
          //   html2canvas: {
          //     scale: scale,
          //     width: 2732,
          //     backgroundColor:"rgba(0,0,0,0)"
          //   },
          //   fontFaces:[{
          //     family:"MyWebFont",
          //     src: [{
          //     format: "truetype",
          //     url: "assets/fonts/WenQuanYiMicroHei.ttf"
          //   }]}]
          // });
        }   
  }
  constructor(private http: HttpClient, private router: Router,private elementRef:ElementRef, private renderer:Renderer2, private titleService: Title,
    private gtmService: GoogleTagManagerService) {  }

  ngOnInit() {
    if(localStorage.getItem("token_front")===null || localStorage.getItem("token_front")==='' || localStorage.getItem("token_front")===undefined){
      this.router.navigate(['/home']);
    }else{
      let customer = JSON.parse(localStorage.getItem("profile_front") || '{}');
      if(parseInt(customer.keyexpire===undefined?"":customer.keyexpire) < new Date().getTime()){
        this.router.navigate(['/home']);
      }
      this.titleService.setTitle("優德莎莉-問卷諮詢");
      if(this.router.url==='/questionnaire/review'){
        if(history.state.data===undefined){
          this.router.navigate(['/customer_profile']);
        }else{
          this.scoreObj = history.state.data;
          this.productsArr = JSON.parse(this.scoreObj.product);
          this.symptoms2UserArr = JSON.parse(this.scoreObj.symptoms2User);
          this.habits2UserArr = JSON.parse(this.scoreObj.habits2User);
          this.showResultDiv = true;
        }
        this.getDatFromMongo("line","line");
        this.getDatFromMongo("frontPageTitle","frontPageTitle");
        this.getQuestions(JSON.parse(this.scoreObj["surveyModel"]));
      }else{
        var obj:{[key: string]: {[key: string]: string}} = {"question":{"collection":"question","datName":"questionDat"},
        "model":{"collection":"model","datName":"modelDat"},
        "symptom":{"collection":"symptom","datName":"symptomDat"},
        "habit":{"collection":"habit","datName":"habitDat"},
        "symptomContent":{"collection":"symptom_content","datName":"symptomContentDat"},
        "product":{"collection":"product","datName":"productDat"},
        "line":{"collection":"line","datName":"line"},
        "frontPageTitle":{"collection":"frontPageTitle","datName":"frontPageTitle"}};
        for(var key in obj){
          this.getDatFromMongo(obj[key]["collection"],obj[key]["datName"]);
        }
      }
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
            const gtmTag = {
                event: 'page',
                pageName: item.url
            };

            this.gtmService.pushTag(gtmTag);
        }
    });

    }
  }
}
