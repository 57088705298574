import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { QuestionComponent } from './question/question.component'
import { ScoreComponent } from './score/score.component'
import { CustomerComponent } from './customer/customer.component'
import { HomeComponent } from './home/home.component'

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'questionnaire', component: QuestionComponent},
  {path: 'questionnaire/review', component: QuestionComponent},
  {path: 'score', component: ScoreComponent},
  {path: 'customer_profile', component: CustomerComponent},
  {path: 'home', component: HomeComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
