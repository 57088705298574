<div class="page-container row d-flex justify-content-between align-items-stretch min-vh-100">
  <div class="col-xl-1 left-bg"></div>
  <div class="col-xl-9 col-lg-12 bg-white shadow p-0 d-flex align-items-center flex-column">
    <div class="title-div d-flex justify-content-center align-items-end">
      <div class="title-div-background d-flex justify-content-end align-items-end">
        <div class="title">智能備孕<br/>檢測系統</div>
      </div>
    </div>
    <div class="personInfo-div">
      <div class="d-flex justify-content-between pt-5 row row-cols-auto">
        <span class="title col col-xs-3 col-md-6">基本資料</span>
        <span class="col col-xs-9 col-md-6 d-flex justify-content-end">
          <button class="me-xxl-2 me-1 save" *ngIf="editStatus" (click)="saveProfile()"></button>
          <button class="me-xxl-2 me-1 cancel" *ngIf="editStatus" (click)="editStatus=!editStatus;customer.name=tmpCustomer.name;customer.birthday=tmpCustomer.birthday"></button>
          <button class="me-xxl-2 me-1" (click)="editProfile()" >編輯個人資料</button>
          <button (click)="logout()">登出</button>
        </span>
      </div>
      <div class="pt-2 pb-5 row row-cols-2 row-cols-lg-auto">
        <span class="sub-title col-xs-3">姓名</span>
        <span class="content ms-lg-4 me-lg-5 col-xs-3" *ngIf="!editStatus">{{customer.name}}</span>
        <span class="content col-xs-5 p-lg-0" *ngIf="editStatus">
          <input type="text" [(ngModel)]="customer.name" class="edit-content" placeholder="請輸入名字"> 
        </span>
        <span class="sub-title col-xs-3">生日</span>
        <span class="content ms-lg-4 col-xs-3" *ngIf="!editStatus">{{customer.birthday}}</span>
        <span class="content col-xs-5 p-lg-0" *ngIf="editStatus">
          <input type="date" [(ngModel)]="customer.birthday" class="edit-content" placeholder="請輸入生日"></span>
      </div>
    </div>
    <div class="result-div">
      <div class="d-flex justify-content-between">
        <span class="title">檢測結果<h5 [hidden]="checkProfile">【請先編輯個人資料】</h5></span>
      </div>
      <div class="d-flex justify-content-start row" #resDiv>
        <div class="new-exam col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe-2 d-flex align-items-stretch"  
        (click)="newQuestion()">
          <div class="d-flex flex-column justify-content-center align-items-center" [ngStyle]="{'height':newExamHeight}">
            <div class="circle-plus">
            </div>
            <span>開始新的檢測</span>
          </div>
        </div>
        <div class="review col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngFor="let record of feedbackTable">
          <div class="d-flex row m-0 p-0 align-items-start" *ngIf="feedbackTable[0].obj.modelScore!=''">
            <div class="col-6 upper-title d-flex flex-column dotY" [ngStyle]="{'height':resHeight}">
              <span class="upper-title">檢測日期</span>
              <span class="date">{{record.keycreat | date:'yyyy'}}<br/>{{record.keycreat | date:'MM/dd'}}</span>
            </div>
            <div class="col-6 upper-title d-flex flex-column" [ngStyle]="{'height':resHeight}">
              <span class="upper-title">好孕分數</span>
              <span class="score">{{record.obj.modelScore |  number: '1.0-0'}}</span>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-center product" [ngStyle]="{'height':resHeight}">
              <span class="down-title">營養品建議補充</span>
              <span class="product-item" [innerHTML]="record.obj.product|productUrl:'json'">
              </span>
            </div>
          </div>
          <div class="col-12 review-res" (click)="reviewRecord(record.obj)" *ngIf="feedbackTable[0].obj.modelScore!=''">
            查看完整結果
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="px-4 pt-0 pb-4 cover">
      <div class="logoutBtn d-flex justify-content-end">
        <button class="btn btn-primary" (click)="logout()">登出</button>
      </div>
      <div class="media d-flex align-items-end profile-head justify-content-between">
        <div class="profile mr-3 col-lg-3 col-xs-3 col-sm-3 col-md-3">
          <img src="https://www.eatlohas.com.tw/wp-content/uploads/2016/12/600x600logo-01.jpg" alt="..." width="130"
            class="rounded mb-2 img-thumbnail border-0">
          <button class="btn btn-sm btn-block"
            [ngClass]="{'btn-outline-dark': checkProfile, 'btn-outline-danger': !checkProfile}"
            (click)="editProfile()"><img alt='輸出pdf !' height='15' width='15'
              src='assets/images/baseline_mode_black_18dp.png' />編輯個人資料</button>
        </div>
        <div class="mb-5 text-white col-xs-6 col-sm-6 col-md-6">
          <h4 class="mt-0 mb-4">Hi 您好</h4>
          <p class="small mb-4"> <i class="fas fa-map-marker-alt mr-2"></i>底下是您的資料以及作答紀錄</p>
        </div>
      </div>
    </div> -->
    <!-- <div class="bg-light p-4 d-flex justify-content-end text-center">
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <h5 *ngIf="grep2MongoTip && feedbackTable[0]!==undefined" class="font-weight-bold mb-0 d-block">
            {{feedbackTable[0].keycreat | date}}</h5>
          <h5 *ngIf="grep2MongoTip && feedbackTable[0].obj.modelScore===''" class="font-weight-bold mb-0 d-block">
            還沒回答問卷過喔</h5>
          <small *ngIf="grep2MongoTip" class="text-muted"> <i class="fas fa-image mr-1"></i>上次作答日期</small>
        </li>
      </ul>
    </div> -->
    <!-- <div class="px-4 py-3">
      <h5 class="mb-0">您的基本資料
        <button class="btn btn-outline-primary btn-sm btn-block" *ngIf="editStatus" (click)="saveProfile()">存檔</button>
        <button class="btn btn-outline-danger btn-sm btn-block" *ngIf="editStatus"
          (click)="editStatus=!editStatus">取消</button>
      </h5>

      <div class="p-4 rounded shadow-sm bg-light">
        <table class="table">
          <tr>
            <th>姓名</th>
            <td *ngIf="!editStatus">{{customer.name}}</td>
            <td *ngIf="editStatus"><input type="text" [(ngModel)]="customer.name" class="input-group-text w-50"
                placeholder="請輸入名字"> </td>
          </tr>
          <tr>
            <th>生日</th>
            <td *ngIf="!editStatus">{{customer.birthday}}</td>
            <td *ngIf="editStatus"><input type="date" [(ngModel)]="customer.birthday" class="input-group-text w-50"
                placeholder="請輸入生日"></td>
          </tr>
        </table>
      </div>
    </div> -->
    <!-- <div class="py-4 px-4" *ngIf="checkProfile">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <span class="d-flex align-items-center">
          <h5 class="mb-0">最近作答紀錄</h5>
          <button *ngIf="!grep2MongoTip" class="btn btn-outline-success">抓取作答紀錄...</button>
          <button *ngIf="grep2MongoTip" class="btn btn-success" (click)="newQuestion()">開始新的作答</button>
        </span>
      </div>
      <div class="container">
        <div class="row justify-content-start">
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 p-4" *ngFor="let record of feedbackTable">
            <div class="record-card" [hidden]="record.obj.modelScore===''">
              <div class="px-3">
                作答日期: {{record.keycreat | date}}
              </div>
              <div class="px-3 pt-3">
                <h3 class="name">好孕分數 - {{record.obj.modelScore}}分</h3>
                <span class="record-card-contant">
                  <ul class="list-group">
                    <li class="list-group-item list-group-item-success">建議</li>
                    <span [innerHTML]="record.obj.habits2User|productUrl:'json':'habit'"></span>
                  </ul>
                </span>
              </div>
              <div class="d-flex justify-content-start px-3 mt-1"> <span class="record-card-contant pl-2">營養品推薦: <span
                    [innerHTML]="record.obj.product|productUrl:'json'"></span></span> </div>
              <div class="d-flex justify-content-between px-3 pb-3 mt-1">
                <button class="btn btn-sm btn-outline-info" (click)="reviewRecord(record.obj)">檢視答案</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="col-xl-1 col-lg-1 right-bg"></div>
</div>