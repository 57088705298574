import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
@Pipe({
  name: 'productUrl'
})
export class ProductUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any, isJson?: any, field?:string): any {
    if(value===undefined){
      return(null)
    }
    if(isJson==='json'){
      if(typeof(value)==='object' && value.length < 1){
        return("")
      }
      value = JSON.parse(value);
      
      if(typeof(value)==='object'){
        if(field==="habit"){
          value = value.map(
            (v:any)=>{return('<li class="list-group-item">'+v.habit+"</li>")}
          ).join("");
        }else{
          value = value.map(
            (v:any)=>{return("<a href='" + v.url + "' target='_blank' rel='noreferrer noopener'>"+v.product+"</a>")}
          ).join("、");
        }
      }
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
